<template>
  <div class="wrapper">
    <div class="cancel">
      <router-link to="/">
        <CloseIcon />
      </router-link>
    </div>

    <div class="auth-banner">
      <img
        src="/img/chop-banner.webp"
        style="width: 100%; border-radius: 10px; max-width: 100%"
      />
    </div>
    <section class="register-form-wrapper p-4" v-show="action_change_password">
      <h3 class="text-center">{{ $t("enterOTP") }}</h3>
      <div class="sent-code text-center mb-4">
        <p class="">{{ $t("sentCodeToPhone") }}</p>
        <br />
        <strong> {{ msisdn }} </strong>
      </div>
      <div class="form-wrapper mb-3">
        <form autocomplete="off">
          <div class="mb-3">
            <input
              type="number"
              class="form-control"
              id="phoneInput"
              :placeholder="$t('phoneNumber071')"
              :aria-label="$t('enterPhoneNumber')"
              name="mobile"
              v-model="msisdn"
            />
          </div>

          <div
            class="verification-code-wrapper mb-3"
            style="display: flex; justify-content: space-between; gap: 10px"
          >
            <input
              v-for="(value, index) in code"
              :key="index"
              class="otp__input"
              type="text"
              inputmode="numeric"
              maxlength="1"
              autocomplete="off"
              autocorrect="off"
              spellcheck="false"
              :name="generateRandomName(`${code[index]}`)"
              v-model.trim="code[index]"
              @input="handleInput(index, $event)"
              @keydown.backspace="handleBackspace(index)"
              @focus="handleFocus(index)"
            />
          </div>

          <div class="mb-0">
            <div class="input-group" style="position: relative">
              <input
                :type="type"
                class="form-control1"
                id="inputPass"
                style="z-index: 2"
                name="password"
                placeholder="Password"
                aria-label="Password"
                v-model="password"
              />
              <span
                class="btn btn-sm password-toggle"
                style="
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  transform: translateY(-50%);
                  background: none;
                  border: none;
                  cursor: pointer;
                  z-index: 3;
                "
                @click="showPassword"
                type="button"
              >
                <img :src="btnText" style="width: 16px" />
              </span>
            </div>
          </div>
          <div class="disclaimer">
            {{ $t("atLeast6CharactersContaining") }}
            <strong>{{ $t("aLetter") }} </strong> and
            <strong>{{ $t("aNumber") }} </strong>.
          </div>
        </form>
      </div>

      <ChopbetButton
        :loading="spinner"
        variant="Variant"
        @click="handleChangePasswordClick"
        :disabled="!msisdn || !password || !fullCode"
        ><span>{{ $t("changePassword") }}</span>
      </ChopbetButton>
    </section>
  </div>
</template>

<script>
import axios from "@/services/identity";
import { pushToDataLayer } from "@/utils/gtm";
import { mapState } from "vuex";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import CloseIcon from "../components/CloseIcon.vue";

export default {
  name: "change-password",
  components: { ChopbetButton, CloseIcon },
  comments: {},
  data: function () {
    return {
      msisdn: "",
      password: "",
      password1: "",
      error: [],
      warning: [],
      success: [],
      loading: "",
      spinner: false,
      action_change_password: true,
      placeBet: 0,
      type: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      showSuccessModal: false,
      code: new Array(4).fill(""),
      fullCode: "",
    };
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState(["referralCode"]),
  },
  watch: {
    code: {
      handler() {
        if (this.code.join("").length === 4) {
          this.fullCode = this.code.join("");
        }
      },
      deep: true,
    },
  },
  methods: {
    handleLoginClick() {
      this.login();
    },
    generateRandomName(baseName) {
      return `${baseName}-${Math.random().toString(36).substring(7)}`;
    },
    trackLoginButtonClick(event) {
      pushToDataLayer("gtm.login", {
        category: "Button",
        action: "Click",
        label: "Login",
        element: event.target,
      });
    },
    handleInput(index, event) {
      if (event.target.value.length === 1) {
        const nextInput = this.$el.querySelectorAll(".otp__input")[index + 1];
        if (nextInput) nextInput.focus();
      }
    },
    handleBackspace(index) {
      if (this.code[index] === "") {
        const prevInput = this.$el.querySelectorAll(".otp__input")[index - 1];
        if (prevInput) prevInput.focus();
      }
    },
    handleFocus(index) {
      if (this.code[index] === "0") {
        this.code[index] = "";
      }
    },

    handleBlur: function () {
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.setError(`${this.$t("pleaseEnterValidMobile")}`);
        document.getElementById("phoneInput").classList.add("is-invalid");
      } else {
        document.getElementById("phoneInput").classList.remove("is-invalid");
      }
    },
    showPassword: function () {
      if (document.getElementById("inputPass").type == "password") {
        document.getElementById("inputPass").type = "text";
      } else {
        document.getElementById("inputPass").type = "password";
      }

      if (this.type === "password") {
        this.type = "text";
        this.btnText = "/img/icons/eye-slash.svg";
      } else {
        this.type = "password";
        this.btnText = "/img/icons/eye.svg";
      }
    },

    handleChangePasswordClick(event) {
      this.changePassword(event);
    },
    trackChangePasswordButtonClick(event) {
      pushToDataLayer("gtm.changePassword", {
        category: "Button",
        action: "Click",
        label: "Change Password",
        element: event.target,
      });
    },
    changePassword: function (event) {
      this.reset();

      if (this.password.length < 4) {
        this.setError(`${this.$t("moreThan4Xters")}`);
        return;
      }

      // Combine the four verification code inputs into a single string
      const fullCode = this.code.join("");

      if (fullCode.length !== 4) {
        this.setError(`${this.$t("valid4Digit")}`);
        return;
      }

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = process.env.VUE_APP_URL_PASSWORD_CHANGE;

      var payload = {
        msisdn: this.msisdn,
        password: this.password,
        code: parseInt(fullCode),
      };

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(JSON.stringify(res));

          vm.spinner = false;
          vm.loading = "";

          vm.trackChangePasswordButtonClick(event);

          vm.setSuccess("Success", `${this.$t("passwordChangeSuccessfully")}`);

          vm.$router.push("/login");
        })
        .catch((err) => {
          vm.spinner = false;
          vm.loading = "";

          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err.request));
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            console.log(JSON.stringify(err));
          }
        });
    },
  },
  mounted: function () {
    this.$store.dispatch("setCurrentPage", "change-password");
    this.placeBet = this.getValue("placeBet");
    var p = this.getProfile();
    if (!p) {
      console.log("");
    } else {
      this.$router.push({ name: "home", params: {} });
      return;
    }
  },
};
</script>

<style src="./index.css" scoped></style>
